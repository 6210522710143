import { defineStore } from "pinia"
import { startOfYear, endOfYear, getYear } from "date-fns"
import { fetchApiJson } from "@/services/repowerAPIService.js"

export default defineStore("invoicesStore", {
  state: () => ({
    portfolioId: null,
    dateRange: [startOfYear(new Date()), endOfYear(new Date())],
    invoices: [],
    loading: false,
    loadingFailed: false,
  }),
  actions: {
    loadInvoices(portfolioId, onSuccess, onFail) {
      this.loading = true
      this.loadingFailed = false
      return fetchApiJson(
        `/v2/portfolios/${portfolioId}/invoices/?year=${getYear(this.dateRange[0])}`,
      )
        .then((data) => {
          this.invoices = data
          onSuccess?.(data)
        })
        .catch((error) => {
          this.invoices = []
          this.loadingFailed = true
          onFail?.(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getInvoicePdf(portfolioId, invoiceNumber, onSuccess, onFail) {
      return fetchApiJson(
        `/v2/portfolios/${portfolioId}/invoices/${invoiceNumber}/pdf/`,
      )
        .then((data) => {
          onSuccess?.(data)
        })
        .catch((error) => {
          onFail?.(error)
        })
    },
  },
})
