<template>
  <BaseCard class="flex flex-col gap-2 p-4 md:gap-4 md:p-6">
    <TextSubtitle v-if="cardTitle" class="mx-2">{{ cardTitle }}</TextSubtitle>
    <LoadingWrapper
      v-if="meteringPoint"
      :error="loadingFailed"
      :loading="loading"
    >
      <!-- Stat row -->
      <div class="flex justify-between">
        <!-- Stat allocation -->
        <StatComponent
          :label="'Energie' + (totals.allocation ? '' : ' (voorlopig)')"
          :value="
            (totals.allocation ?? totals[provisionalKeyMap.allocation]) * 1000
          "
          :precision="2"
          unit="Wh"
          class="flex-1"
        >
          <template #icon>
            <BaseIcon name="flashlight" filled class="fill-energy" />
          </template>
          <template #description="{ label }">
            <span class="text-xs md:text-base">
              {{ label }}
            </span>
            <WikiTooltipComponent
              path="/platform/results/energy"
              class="top-1 ml-1 inline-block md:top-0.5"
            />
          </template>
        </StatComponent>
        <!-- Stat revenue -->
        <StatComponent
          :label="'Resultaat' + (totals.revenue ? '' : ' (voorlopig)')"
          :value="totals.revenue ?? totals[provisionalKeyMap.revenue]"
          :precision="2"
          format="€ _"
          class="flex-1"
        >
          <template #icon>
            <BaseIcon name="money-euro-circle" filled class="fill-finance" />
          </template>
          <template #description="{ label }">
            <span class="text-xs md:text-base">
              {{ label }}
            </span>
            <WikiTooltipComponent
              path="/platform/results/result"
              class="top-1 ml-1 inline-block md:top-0.5"
            />
          </template>
        </StatComponent>
      </div>
      <!-- Chart -->
      <div class="h-[350px]">
        <TimeseriesChart
          v-if="chartData"
          :chart-type="step === 'minute' ? 'step' : 'bar'"
          :series="series"
          :axes="axes"
          :data="chartData"
          :x-limits="chartLimits"
          :time-unit="['day', 'month', 'year'].includes(step) ? step : null"
          aligned-axes
        >
          <template #left-axis-title="{ title }">
            <BaseIcon
              :name="mode !== 'finance' ? 'flashlight' : 'money-euro-circle'"
              filled
              class="text-lg"
              :class="mode !== 'finance' ? 'fill-energy' : 'fill-finance'"
            />
            {{ title }}
          </template>
          <template v-if="mode === 'both'" #right-axis-title="{ title }">
            <BaseIcon
              name="money-euro-circle"
              filled
              class="fill-finance text-lg"
            />
            {{ title }}
          </template>
          <template
            v-for="serie in Object.values(provisionalKeyMap)"
            :key="serie"
            #[serie]="{ label }"
          >
            {{ label }}
            <WikiTooltipComponent
              path="/platform/results/provisional-results"
              class="top-0.5 inline-block"
            />
          </template>
          <template v-if="meteringPoint.ean" #legend-annotation>
            <BaseBadge>
              <span class="font-bold">EAN</span>&nbsp;{{ meteringPoint.ean }}
            </BaseBadge>
          </template>
        </TimeseriesChart>
      </div>
    </LoadingWrapper>
    <div v-else class="flex h-full items-center justify-center italic md:p-6">
      <p>Maak een selectie om gegevens te kunnen bekijken</p>
    </div>
  </BaseCard>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  endOfDay,
  startOfMonth,
  startOfYear,
} from "date-fns"
import { debounce, DEBOUNCE_DELAY } from "@/helpers/debounce.js"
import { colors } from "@/services/themeService.js"
import useNotificationStore from "@/stores/notificationStore.js"
import {
  BaseCard,
  BaseIcon,
  BaseBadge,
  TextSubtitle,
  LoadingWrapper,
  TimeseriesChart,
} from "@repowerednl/ui-component-library"
import StatComponent from "@/components/information/StatComponent.vue"
import WikiTooltipComponent from "@/components/information/WikiTooltipComponent.vue"

const props = defineProps({
  /**
   * Can be either an asset or a portfolio.
   */
  meteringPoint: {
    type: Object,
    default: null,
  },
  /**
   * Array of two Date objects; the last day is included in the range.
   */
  dateRange: {
    type: Array,
    required: true,
  },
  /**
   * Determines which data series are shown/hidden.
   */
  mode: {
    required: true,
    validator: (value) => ["energy", "finance", "both"].includes(value),
  },
  cardTitle: {
    type: String,
    default: "",
  },
})

const DAYS_MINUTE = 5
const DAYS_DAY = 32
const MONTHS_YEAR = 25

const notificationStore = useNotificationStore()
const recordsData = ref()
const loading = ref(false)
const loadingFailed = ref(false)

const recordTypeMap = {
  allocation: {
    ALLOCATION_FEED_IN_VOLUME: { sign: +1 },
    ALLOCATION_TAKE_OFF_VOLUME: { sign: -1 },
    "Allocation Sell (kWh)": { sign: +1, excl: 1 },
    "Allocation Buy (kWh)": { sign: -1 },
    "Allocation (kWh)": { sign: +1, excl: 1 },
    "Allocation Feed-in (kWh)": { sign: +1 },
    "Allocation Take-off (kWh)": { sign: -1 },
  },
  nomination: {
    DAY_AHEAD_FEED_IN_VOLUME: { sign: +1 },
    DAY_AHEAD_TAKE_OFF_VOLUME: { sign: -1 },
    "Nomination Sell (kWh)": { sign: +1 },
    "Nomination Buy (kWh)": { sign: -1 },
  },
  revenue: {
    TOTAL: { sign: +1 },
    Total: { sign: +1 },
  },
}

const provisionalKeyMap = {
  allocation: "allocationProvisional",
  revenue: "revenueProvisional",
  nomination: "nominationProvisional",
}

const freq = {
  minute: "15min",
  day: "1D",
  month: "MS",
  year: "YS",
}

const step = computed(() => {
  const days =
    1 + differenceInCalendarDays(props.dateRange[1], props.dateRange[0])
  const months =
    1 + differenceInCalendarMonths(props.dateRange[1], props.dateRange[0])
  if (days < DAYS_MINUTE) {
    return "minute"
  } else if (days < DAYS_DAY) {
    return "day"
  } else if (months < MONTHS_YEAR) {
    return "month"
  } else {
    return "year"
  }
})

const axes = {
  energy: {
    title: "Energie",
    unit: "Wh",
    precision: 0,
  },
  finance: {
    title: "Financieel",
    format: "€ _",
    precision: 0,
  },
}

const series = computed(() => {
  const obj = {
    allocation: {
      label: "Energie",
      axis: "energy",
      rescale: 1000,
      colorScale: colors.energy,
      stack: "allocation",
    },
    allocationProvisional: {
      label: "Energie (voorlopig)",
      axis: "energy",
      rescale: 1000,
      secondary: true,
      colorScale: colors.energy,
      stack: "allocation",
    },
    nomination: {
      label: "Nominatie",
      axis: "energy",
      rescale: 1000,
      colorScale: colors.lightgray,
      stack: "nomination",
    },
    nominationProvisional: {
      label: "Nominatie (voorlopig)",
      axis: "energy",
      rescale: 1000,
      secondary: true,
      colorScale: colors.lightgray,
      stack: "nomination",
    },
    revenue: {
      label: "Resultaat",
      axis: "finance",
      colorScale: colors.finance,
      stack: "revenue",
    },
    revenueProvisional: {
      label: "Resultaat (voorlopig)",
      axis: "finance",
      secondary: true,
      colorScale: colors.finance,
      stack: "revenue",
    },
  }
  switch (props.mode) {
    case "energy":
      delete obj.revenue
      delete obj.revenueProvisional
      break
    case "finance":
      delete obj.allocation
      delete obj.allocationProvisional
      delete obj.nomination
      delete obj.nominationProvisional
      break
    default:
      delete obj.nomination
      delete obj.nominationProvisional
      break
  }
  // Don't stack regular and provisional results for minute data.
  if (step.value === "minute") {
    for (const key in obj) {
      delete obj[key].stack
    }
  }
  return obj
})

const chartLimits = computed(() => {
  switch (step.value) {
    case "minute":
      return [props.dateRange[0], endOfDay(props.dateRange[1])]
    case "month":
      return [props.dateRange[0], startOfMonth(props.dateRange[1])]
    case "year":
      return [props.dateRange[0], startOfYear(props.dateRange[1])]
    default:
      return [props.dateRange[0], props.dateRange[1]]
  }
})

function categorizeRecord(record) {
  const result = {}
  for (const [serie, specs] of Object.entries(recordTypeMap)) {
    let stable = true
    let value = null
    for (const [type, { sign }] of Object.entries(specs)) {
      if (type in record) {
        stable = stable && record[type].stable
        value = (value ?? 0) + sign * record[type].value
      }
    }
    if (value !== null) {
      const targetSeries = stable ? serie : provisionalKeyMap[serie]
      result[targetSeries] = value
    }
  }
  return result
}

const chartData = computed(() => {
  if (!recordsData.value) {
    return {}
  }
  const result = {}
  for (const [datetime, { data }] of Object.entries(
    recordsData.value.records,
  )) {
    result[datetime] = categorizeRecord(data)
  }
  return result
})

const totals = computed(() => categorizeRecord(recordsData.value.totals.data))

function onNewRecords(newRecords) {
  recordsData.value = newRecords
  loading.value = false
}

function onLoadRecordsFailed(error) {
  loadingFailed.value = true
  loading.value = false
  notificationStore.pushError(
    "Fout bij het ophalen van resultaten",
    `De resultaten konden niet worden opgehaald. Probeer het later opnieuw. (code: ${error.code})`,
    "load-records-error",
  )
}

const loadData = debounce((meteringPoint, dateRange) => {
  meteringPoint.loadResults(
    freq[step.value],
    dateRange,
    false,
    onNewRecords,
    onLoadRecordsFailed,
  )
}, DEBOUNCE_DELAY)

watch(
  [() => props.meteringPoint, () => props.dateRange],
  ([meteringPoint, dateRange]) => {
    recordsData.value = null
    if (!meteringPoint) {
      return
    }
    loading.value = true
    loadingFailed.value = false
    loadData(meteringPoint, dateRange)
  },
  { immediate: true },
)
</script>
