import { defineStore } from "pinia"
import {
  makeDataFetchCache,
  cachedDataFetch,
} from "@/services/repowerAPIService.js"

function loadPrices(cache, slug, dateRange, onSuccess, onFail) {
  cachedDataFetch(
    `/v2/market-prices/${slug}/`,
    cache,
    null,
    dateRange,
    onSuccess,
    onFail,
  )
}

export default defineStore("marketPricesStore", {
  state: () => ({
    loading: false,
    dayAheadPrices: makeDataFetchCache(),
    balanceDeltaPrices: makeDataFetchCache(),
  }),
  actions: {
    loadDayAheadPrices(dateRange, onSuccess, onFail) {
      loadPrices(this.dayAheadPrices, "day-ahead", dateRange, onSuccess, onFail)
    },
    loadBalanceDeltaPrices(dateRange, onSuccess, onFail) {
      loadPrices(
        this.balanceDeltaPrices,
        "balance-delta",
        dateRange,
        onSuccess,
        onFail,
      )
    },
  },
})
