import config from "/config.js"
import { format, addDays } from "date-fns"
import useSessionStore from "@/stores/sessionStore"
import HTTP_STATUS from "@/helpers/httpStatusHelper.js"

const BASE_URL = `${config.API_URL}/api`

export function fetchApi(path, options = {}) {
  const url = `${BASE_URL}${path}`
  return fetch(url, options)
}

export function fetchApiCatch(path, options, toJSON = false) {
  return fetchApi(path, options).then((response) => {
    if (response.status === HTTP_STATUS.OK) {
      return toJSON ? response.json() : response
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

export function fetchApiJson(path, options = {}) {
  return fetchApiCatch(path, options, true)
}

export function postApi(path, body, options) {
  const sessionStore = useSessionStore()
  return fetchApi(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": sessionStore.csrfToken,
    },
    credentials: "same-origin",
    body: JSON.stringify(body),
    ...options,
  })
}

export function postApiCatch(path, body, options, toJSON = false) {
  return postApi(path, body, options).then((response) => {
    if (response.status === HTTP_STATUS.OK) {
      return toJSON ? response.json() : response
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

export function postApiJson(path, body, options) {
  return postApiCatch(path, body, options, true)
}

/**
 * Construct a path with the given frequency and date range query parameters.
 */
function constructPath(endpoint, freq, dateRange) {
  const paramsObj = new URLSearchParams({
    freq,
    date_start: format(dateRange[0], "yyyy-MM-dd"),
    date_end: format(addDays(dateRange[1], 1), "yyyy-MM-dd"),
  })
  paramsObj.delete("freq", null)
  let path = endpoint
  if (!endpoint.endsWith("?") || !endpoint.endsWith("&")) {
    path += endpoint.includes("?") ? "&" : "?"
  }
  path += paramsObj.toString()
  return path
}

/**
 * Fetch data from an endpoint and call either onSuccess or onFail depending on
 * the result.
 */
export function dataFetch(endpoint, freq, dateRange, onSuccess, onFail) {
  const path = constructPath(endpoint, freq, dateRange)
  return fetchApiJson(path)
    .then((data) => onSuccess?.(data))
    .catch((error) => onFail?.(error))
}

/**
 * Construct a data fetch object with null values, used for `cachedDataFetch`.
 */
export function makeDataFetchCache() {
  return {
    path: null,
    data: null,
    promise: null,
  }
}

/**
 * Fetch data from an endpoint or cache and call either onSuccess or onFail
 * depending on the result. The cache is used to
 * - Avoid redundant calls to the same path
 * - Prevent the callbacks from being called if a more recent request has been
 *   made for the same cache
 */
export function cachedDataFetch(
  endpoint,
  cache,
  freq,
  dateRange,
  onSuccess,
  onFail,
) {
  const path = constructPath(endpoint, freq, dateRange)
  // Check for cached data or promise
  let promise
  if (cache.path === path) {
    if (cache.data) {
      onSuccess?.(cache.data)
      return Promise.resolve(cache.data)
    }
    promise = cache.promise ?? fetchApiJson(path)
  } else {
    promise = fetchApiJson(path)
  }
  // Fetch data if it's still the most recent promise
  cache.path = path
  cache.promise = promise
  cache.data = null
  return promise
    .then((data) => {
      if (promise === cache.promise) {
        cache.data = data
        onSuccess?.(data)
      }
    })
    .catch((error) => {
      if (promise === cache.promise) {
        onFail?.(error)
      }
    })
}
