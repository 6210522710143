import config from "/config.js"
import { defineStore } from "pinia"
import { format, addDays } from "date-fns"
import {
  fetchApiJson,
  makeDataFetchCache,
  dataFetch,
  cachedDataFetch,
} from "@/services/repowerAPIService.js"

/* -------------------------------- Constants ------------------------------- */

export const PORTFOLIO = 1
export const ALLOCATION_POINT = 2
export const ASSET = 3

/* --------------------------------- Methods -------------------------------- */

const baseGetters = {
  id: (state) => state.properties?.id,
  name: (state) => state.properties?.name,
  demo: (state) => state.properties?.demo,
}

function setMeteringPoint(asset) {
  this.properties = asset
  this.status = asset.status
}

function loadStatus(onSuccess, onFail) {
  fetchApiJson(`${this.endpoint}/status/`)
    .then((data) => {
      this.status = data
      onSuccess?.(data)
    })
    .catch((error) => {
      this.status = null
      onFail?.(error)
    })
}

function loadMeasurements(freq, dateRange, getExport, onSuccess, onFail) {
  const measurementTypes = this.type === "Battery" ? "power,soc" : "power"
  const endpoint = `${this.endpoint}/measurements/${getExport ? "export/" : ""}?measurement_type=${measurementTypes}`
  if (getExport) {
    dataFetch(endpoint, freq, dateRange, onSuccess, onFail)
  } else {
    cachedDataFetch(
      endpoint,
      this.measurements,
      freq,
      dateRange,
      onSuccess,
      onFail,
    )
  }
}

function loadCalculatedRevenue(freq, dateRange, onSuccess, onFail) {
  const endpoint = `${this.endpoint}/calculated-revenue/?revenue_types=imbalance`
  cachedDataFetch(
    endpoint,
    this.calculatedRevenue,
    freq,
    dateRange,
    onSuccess,
    onFail,
  )
}

function loadBatteryCycles(dateRange, onSuccess, onFail) {
  const params = new URLSearchParams({
    date_start: format(dateRange[0], "yyyy-MM-dd"),
    date_end: format(addDays(dateRange[1], 1), "yyyy-MM-dd"),
  }).toString()
  fetchApiJson(`${this.endpoint}/kpis/battery-cycles/?${params}`)
    .then((data) => {
      onSuccess?.(data)
    })
    .catch((error) => {
      onFail?.(error)
    })
}

function loadRecords(freq, dateRange, getExport, onSuccess, onFail) {
  const endpoint = `/v2/records/${this.id}/${getExport ? "export/" : ""}`
  if (getExport) {
    dataFetch(endpoint, freq, dateRange, onSuccess, onFail)
  } else {
    cachedDataFetch(endpoint, this.records, freq, dateRange, onSuccess, onFail)
  }
}

async function loadRecordStreams(
  freq,
  dateRange,
  getExport,
  onSuccess,
  onFail,
) {
  // Fetch collections first
  if (!this.collections) {
    try {
      this.collections = await fetchApiJson(
        `${this.endpoint}/record-stream-collections/`,
      )
    } catch (error) {
      onFail?.(error)
      return
    }
  }
  // Fetch record streams from first collection
  const uid = this.collections[0]?.collection_id
  if (!uid) {
    onFail?.()
    return
  }
  const endpoint = `/v2/record-streams/${uid}/${getExport ? "export/" : ""}?format_as_records=true`
  if (getExport) {
    dataFetch(endpoint, freq, dateRange, onSuccess, onFail)
  } else {
    cachedDataFetch(
      endpoint,
      this.recordStreams,
      freq,
      dateRange,
      onSuccess,
      onFail,
    )
  }
}

function loadResults(freq, dateRange, getExport, onSuccess, onFail) {
  const useLegacy = dateRange[0] < config.LEGACY_RESULTS_BREAKPOINT
  if (useLegacy) {
    return this.loadRecordStreams(freq, dateRange, getExport, onSuccess, onFail)
  }
  return this.loadRecords(freq, dateRange, getExport, onSuccess, onFail)
}

function loadFcrAllocations(dateRange, onSuccess, onFail) {
  const endpoint = `${this.endpoint}/fcr-allocations/`
  cachedDataFetch(
    endpoint,
    this.fcrAllocations,
    null,
    dateRange,
    onSuccess,
    onFail,
  )
}

function loadSetpoints(dateRange, onSuccess, onFail) {
  const endpoint = `${this.endpoint}/setpoints/`
  cachedDataFetch(endpoint, this.setpoints, null, dateRange, onSuccess, onFail)
}

/* ---------------------------- Store definitions --------------------------- */

export function usePortfolioStoreFactory(id) {
  return defineStore(`PORTFOLIO-${id}`, {
    state: () => ({
      role: PORTFOLIO,
      endpoint: `/v2/portfolios/${id}`,
      properties: {},
      records: makeDataFetchCache(),
      recordStreams: makeDataFetchCache(),
      collections: null,
    }),
    getters: baseGetters,
    actions: {
      setMeteringPoint,
      loadRecords,
      loadRecordStreams,
      loadResults,
    },
  })() // <-- Note the () here to call the function
}

export function useAllocationPointStoreFactory(id) {
  return defineStore(`ALLOCATION-POINT-${id}`, {
    state: () => ({
      role: ALLOCATION_POINT,
      endpoint: `/v2/allocation-points/${id}`,
      properties: {},
      records: makeDataFetchCache(),
      recordStreams: makeDataFetchCache(),
      collections: null,
    }),
    getters: {
      ...baseGetters,
      portfolioId: (state) => state.properties?.portfolio_id,
    },
    actions: {
      setMeteringPoint,
      loadRecords,
      loadRecordStreams,
      loadResults,
    },
  })() // <-- Note the () here to call the function
}

const iconMap = {
  SolarPark: "sun",
  WindPark: "windy",
  Battery: "battery",
  Consumer: "database",
  ChargingStation: "plug",
}

export function useAssetStoreFactory(id) {
  return defineStore(`ASSET-${id}`, {
    state: () => ({
      role: ASSET,
      endpoint: `/v2/assets/${id}`,
      properties: {},
      status: null,
      measurements: makeDataFetchCache(),
      calculatedRevenue: makeDataFetchCache(),
      fcrAllocations: makeDataFetchCache(),
      setpoints: makeDataFetchCache(),
    }),
    getters: {
      ...baseGetters,
      type: (state) => state.properties?.type,
      portfolioId: (state) => state.properties?.portfolio_id,
      allocationPointId: (state) => state.properties?.allocation_point_id,
      icon: (state) => iconMap[state.properties?.type] ?? "question",
    },
    actions: {
      setMeteringPoint,
      loadStatus,
      loadMeasurements,
      loadCalculatedRevenue,
      loadBatteryCycles,
      loadFcrAllocations,
      loadSetpoints,
    },
  })() // <-- Note the () here to call the function
}
