<template>
  <div class="flex flex-col gap-4">
    <!-- Header -->
    <TextHeader>Resultaten</TextHeader>
    <!-- Filter bar -->
    <ToolbarComponent
      with-portfolio
      no-assets
      :datepicker-breakpoints="[config.LEGACY_RESULTS_BREAKPOINT]"
      @breakpoint-limit="onDateBreakpointLimit"
    >
      <ExportComponent
        :metering-point="filterStore.currentMeteringPoint"
        :date-range="filterStore.dateRange"
        type="results"
      />
    </ToolbarComponent>
    <MessageComponent
      v-if="filterStore.currentMeteringPoint?.demo"
      color="info"
    >
      {{ $p("demo-disclaimer") }}
    </MessageComponent>
    <!-- Chart panel -->
    <ResultsChartPanel
      :metering-point="filterStore.currentMeteringPoint"
      :date-range="filterStore.dateRange"
      :mode="filterStore.mode"
    />
    <!-- Table panel -->
    <BaseDivider />
    <ResultsTablePanel
      :metering-point="filterStore.currentMeteringPoint"
      :date-range="filterStore.dateRange"
      :mode="filterStore.mode"
    />
  </div>
</template>

<script setup>
import config from "/config.js"
import { onBeforeMount } from "vue"
import { startOfToday, startOfMonth, lastDayOfMonth } from "date-fns"
import useFilterStore from "@/stores/filterStore.js"
import useNotificationStore from "@/stores/notificationStore.js"
import $p from "@/services/phraseService.js"
import { TextHeader, BaseDivider } from "@repowerednl/ui-component-library"
import ToolbarComponent from "@/components/navigation/ToolbarComponent.vue"
import MessageComponent from "@/components/information/MessageComponent.vue"
import ExportComponent from "@/components/information/ExportComponent.vue"
import ResultsChartPanel from "@/components/panels/ResultsChartPanel.vue"
import ResultsTablePanel from "@/components/panels/ResultsTablePanel.vue"

const filterStore = useFilterStore()
const notificationStore = useNotificationStore()

function onDateBreakpointLimit() {
  notificationStore.pushToast(
    "Datumselectie begrensd",
    "Data van vóór 2025 kan niet tegelijk met nieuwe data worden getoond i.v.m. verschillende systemen. Maak een nieuwe selectie in een van beide periodes om meer resultaten te zien.",
    "datepicker-breakpoint",
  )
}

onBeforeMount(() => {
  filterStore.currentMeteringPoint = null
  filterStore.dateRange = [
    startOfMonth(startOfToday()),
    lastDayOfMonth(startOfToday()),
  ]
})
</script>
