<template>
  <div class="flex flex-col gap-4">
    <TextTitle>Gegevens</TextTitle>
    <MeteringPointCard
      :metering-point="filterStore.currentMeteringPoint"
      class="p-4 md:p-6"
    >
      <div class="flex flex-col justify-between gap-8 lg:flex-row">
        <div
          class="grid h-fit flex-shrink-0 grid-cols-[auto_auto] gap-x-4 gap-y-2 odd:*:text-gray-300 even:[&_p]:font-medium"
        >
          <p>Naam</p>
          <p>{{ assetProps.name }}</p>
          <p>Status</p>
          <div class="flex items-center">
            <StatusIndicatorComponent
              :asset="filterStore.currentMeteringPoint"
              no-tooltip
              class="mr-1.5"
            />
            <p>{{ $p(assetProps.status.description_key) }}</p>
          </div>
          <p>Locatie</p>
          <p>{{ assetProps.address.address }}, {{ assetProps.address.city }}</p>
          <p>Type</p>
          <p>{{ phraseMapType[assetProps.type] ?? assetProps.type }}</p>
          <template v-if="assetProps.type === 'SolarPark'">
            <p>DC vermogen</p>
            <p>{{ formatUnit(assetProps.dc_capacity_kwp * 1000, "Wp") }}</p>
          </template>
          <p>EMS Leverancier</p>
          <p>{{ assetProps.ems_provider }}</p>
          <template v-if="assetProps.wind_turbine_properties">
            <p class="col-span-2">
              Turbine eigenschappen <BaseIcon name="arrow-down-s" />
            </p>
            <BaseCard
              class="col-span-2 mt-1 overflow-hidden shadow-transparent"
              inner
            >
              <BaseTable
                :columns="windTurbinePropertiesColumns"
                :rows="windTurbinePropertiesRows"
              />
            </BaseCard>
          </template>
        </div>

        <div class="flex flex-col gap-4 lg:max-w-96">
          <img
            v-if="mapImage"
            class="aspect-[3/2] rounded-lg"
            :src="mapImage"
            alt="Map"
          />
          <a
            :href="`https://www.google.com/maps/@?api=1&map_action=map&center=${assetProps.address.latitude},${assetProps.address.longitude}&zoom=17&basemap=satellite`"
            target="_blank"
          >
            <BaseButton color="secondary" class="lg:ml-auto">
              Bekijk op Google Maps
              <BaseIcon name="external-link" class="ml-1" />
            </BaseButton>
          </a>
        </div>
      </div>
    </MeteringPointCard>
  </div>
  <!-- SDE -->
  <div v-if="hasSDE" class="flex flex-col gap-4">
    <BaseDivider class="py-2" />
    <TextTitle>SDE Beschikking</TextTitle>
    <MeteringPointCard
      :metering-point="filterStore.currentMeteringPoint"
      class="p-4 md:p-6"
    >
      <div class="flex flex-col gap-4">
        <div class="flex flex-col justify-between gap-8 lg:flex-row">
          <div
            class="grid h-fit flex-shrink-0 grid-cols-[auto_auto] gap-x-4 gap-y-2 odd:*:text-gray-300 even:*:font-medium"
          >
            <template v-for="(item, index) in sdeFields" :key="index">
              <p>{{ item.label }}</p>
              <p>{{ item.value }}</p>
            </template>
          </div>
        </div>
        <BaseCard class="max-h-[720px] overflow-x-auto">
          <BaseTable :columns="SDEFinancialColumns" :rows="SDEFinancialRows">
            <template
              v-for="(column, index) in Object.keys(SDEFinancialColumns)"
              :key="index"
              #[`${column}-max-row`]="{ row }"
            >
              <p class="font-bold">{{ row[column] }}</p>
            </template>
          </BaseTable>
        </BaseCard>
      </div>
    </MeteringPointCard>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import $p from "@/services/phraseService.js"
import { formatCurrency, formatUnit } from "@/services/formatterService.js"
import useFilterStore from "@/stores/filterStore.js"
import { staticMap } from "@/services/mapboxApiService.js"
import {
  TextTitle,
  BaseIcon,
  BaseButton,
  BaseTable,
  BaseDivider,
  BaseCard,
} from "@repowerednl/ui-component-library"
import MeteringPointCard from "@/components/panels/MeteringPointCard.vue"
import StatusIndicatorComponent from "@/components/information/StatusIndicatorComponent.vue"

const filterStore = useFilterStore()
const phraseMapType = {
  SolarPark: "Zonnepark",
  WindPark: "Windmolenpark",
  Battery: "Batterij",
  Consumer: "Verbruik",
  ChargingStation: "Oplaadpunt",
}
const mapImage = ref()
const SDE_TABLE_FONT_SIZE = "!text-base"

const assetProps = computed(() => {
  return filterStore.currentMeteringPoint?.properties || {}
})

const windTurbinePropertiesColumns = {
  name: { label: "Naam" },
  hub_height: { label: "Masthoogte" },
  rotor_diameter: { label: "Rotordiameter" },
}

const windTurbinePropertiesRows = computed(() =>
  Object.entries(assetProps.value.wind_turbine_properties).map(
    ([key, value]) => ({
      name: key,
      hub_height: `${value.hub_height} m`,
      rotor_diameter: `${value.rotor_diameter} m`,
    }),
  ),
)

const hasSDE = computed(() => {
  return !!(SDE.value && SDE.value.decisionYear)
})

const sdeFields = computed(() => [
  { label: "Beschikkingsjaar", value: SDE.value.decisionYear || "-" },
  { label: "Nominaal vermogen (kWp)", value: SDE.value.power || "-" },
  { label: "Vollasturen", value: SDE.value.totalHours || "-" },
])

const SDE = computed(() => {
  if (!assetProps.value.sde_decision) {
    return {}
  } else {
    const sde = assetProps.value.sde_decision
    return {
      decisionYear: sde.year,
      power: formatUnit(sde.subsidized_power * 1000, "Wp"),
      totalHours: sde.full_load_hours,
    }
  }
})

const SDEFinancialColumns = {
  financial_data: {
    label: "Financiële gegevens",
    classes: SDE_TABLE_FONT_SIZE,
  },
  net_delivery: {
    label: "Netlevering",
    classes: SDE_TABLE_FONT_SIZE,
  },
  non_net_delivery: {
    label: "Niet netlevering",
    classes: SDE_TABLE_FONT_SIZE,
  },
}

const SDEFinancialRows = computed(() => {
  if (!assetProps.value.sde_decision) {
    return []
  } else {
    return [
      {
        financial_data: "Fasebedrag",
        net_delivery: `${formatCurrency(assetProps.value.sde_decision.phase_price)} per MWh`,
        non_net_delivery: `${formatCurrency(assetProps.value.sde_decision.phase_price)} per MWh`,
        _id: "phase-row",
      },
      {
        financial_data: "Basisbedrag",
        net_delivery: `${formatCurrency(assetProps.value.sde_decision.base_energy_price_feed_in)} per MWh`,
        non_net_delivery: `${formatCurrency(assetProps.value.sde_decision.base_energy_price_not_feed_in)} per MWh`,
        _id: "base-row",
      },
      {
        financial_data: "Maximale subsidietarief",
        net_delivery: `${formatCurrency(assetProps.value.sde_decision.phase_price - assetProps.value.sde_decision.base_energy_price_feed_in)} per MWh`,
        non_net_delivery: `${formatCurrency(assetProps.value.sde_decision.phase_price - assetProps.value.sde_decision.base_energy_price_not_feed_in)} per MWh`,
        _id: "max-row",
      },
    ]
  }
})

watch(
  () => filterStore.currentMeteringPoint,
  () => {
    mapImage.value = null
    if (filterStore.currentMeteringPoint) {
      staticMap(
        assetProps.value.address.longitude,
        assetProps.value.address.latitude,
        (blob) => {
          mapImage.value = URL.createObjectURL(blob)
        },
      )
    }
  },
  { immediate: true },
)
</script>
