<template>
  <MeteringPointCard :metering-point :show-title>
    <LoadingWrapper :error="loadingFailed" :loading="loading">
      <!-- Stat row -->
      <div v-if="showStats" class="flex justify-between">
        <!-- Stat total cycles -->
        <StatComponent
          v-if="meteringPoint.type === 'Battery'"
          label="Totaal cycli periode"
          :value="totalBatteryCycles"
          :precision="2"
          class="flex-1"
        >
          <template #icon>
            <BaseIcon name="battery" filled class="fill-primary" />
          </template>
          <template #description="{ label }">
            {{ label }}
            <WikiTooltipComponent
              path="/platform/measurements/battery-cycles"
              class="top-0.5 inline-block"
            />
          </template>
        </StatComponent>
        <!-- Stat avg cycles -->
        <StatComponent
          v-if="meteringPoint.type === 'Battery'"
          label="Gem. cycli per dag"
          :value="avgBatteryCycles"
          :precision="2"
          class="flex-1"
        >
          <template #icon>
            <BaseIcon name="refresh" filled class="fill-primary" />
          </template>
          <template #description="{ label }">
            {{ label }}
            <WikiTooltipComponent
              path="/platform/measurements/battery-cycles"
              class="top-0.5 inline-block"
            />
          </template>
        </StatComponent>
      </div>
      <!-- Chart -->
      <div :style="{ height: 350 + annotationData.length * 24 + 'px' }">
        <TimeseriesChart
          :chart-type="meteringPoint.type === 'Battery' ? 'step' : 'area'"
          :series="series"
          :axes="axes"
          :data="chartData"
          :annotation-data="annotationData"
          :x-limits="[dateRange[0], endOfDay(dateRange[1])]"
        />
      </div>
    </LoadingWrapper>
  </MeteringPointCard>
</template>

<script setup>
import { ref, computed, watch } from "vue"
import { differenceInCalendarDays, endOfDay } from "date-fns"
import { colors } from "@/services/themeService.js"
import { debounce, DEBOUNCE_DELAY } from "@/helpers/debounce.js"
import useNotificationStore from "@/stores/notificationStore.js"
import {
  BaseIcon,
  LoadingWrapper,
  TimeseriesChart,
} from "@repowerednl/ui-component-library"
import { ASSET } from "@/stores/repower/meteringPointStoreFactories.js"
import MeteringPointCard from "@/components/panels/MeteringPointCard.vue"
import StatComponent from "@/components/information/StatComponent.vue"
import WikiTooltipComponent from "@/components/information/WikiTooltipComponent.vue"

const props = defineProps({
  meteringPoint: {
    type: Object,
    default: null,
  },
  dateRange: {
    type: Array,
    required: true,
  },
  showStats: Boolean,
  showAnnotations: Boolean,
  showTitle: Boolean,
})

const DAYS_1MIN = 2
const DAYS_5MIN = 6
const DAYS_15MIN = 15
const DAYS_1H = 32

const notificationStore = useNotificationStore()
const chartData = ref({})
const loading = ref(false)
const loadingFailed = ref(false)
const totalBatteryCycles = ref()
const avgBatteryCycles = ref()
const annotationData = ref([])

const axes = {
  energy: {
    title: "Vermogen",
    unit: "W",
  },
  percentage: {
    title: "State of charge",
    format: "_%",
    limits: [0, 100],
  },
}

const series = computed(() => {
  const basis = {
    power: {
      label: "Vermogen",
      axis: "energy",
      rescale: 1000,
      colorScale: colors.energy,
    },
    soc: {
      label: "State of charge",
      axis: "percentage",
      rescale: 100,
      colorScale: colors.primary,
    },
  }
  if (props.meteringPoint.type !== "Battery") {
    delete basis.soc
  }
  return basis
})

const freq = computed(() => {
  const days =
    1 + differenceInCalendarDays(props.dateRange[1], props.dateRange[0])
  if (days < DAYS_1MIN) {
    return "1min"
  } else if (days < DAYS_5MIN) {
    return "5min"
  } else if (days < DAYS_15MIN) {
    return "15min"
  } else if (days < DAYS_1H) {
    return "1h"
  } else {
    return "1D"
  }
})

function onNewMeasurements(data) {
  chartData.value = data
  loading.value = false
}

function onLoadMeasurementsFailed(error) {
  loadingFailed.value = true
  notificationStore.pushError(
    "Fout bij het ophalen van metingen",
    `De metingen van een asset konden niet worden opgehaald. Probeer het later opnieuw. (code: ${error.code})`,
    "load-metering-point-data-error",
  )
}

function onNewBatteryCycles(data) {
  totalBatteryCycles.value = data.total
  avgBatteryCycles.value = data.avg
}

function onLoadBatteryCyclesFailed(error) {
  notificationStore.pushError(
    "Fout bij het ophalen van batterijcycli",
    `De batterijcycli van een asset konden niet worden opgehaald. Probeer het later opnieuw. (code: ${error.code})`,
    "load-metering-point-data-error",
  )
}

function onNewAnnotations(data, label, colorScale, valueKey) {
  if (!data.length) {
    return
  }
  annotationData.value.push({
    label,
    colorScale,
    unit: "W",
    precision: 0,
    data: data.map((entry) => ({
      datetimeStart: entry.datetime_start,
      datetimeStop: entry.datetime_stop,
      value: entry[valueKey] * 1000,
    })),
  })
}

function onLoadAnnotationsFailed(error) {
  notificationStore.pushError(
    "Fout bij het ophalen van annotaties",
    `Annotatiedata van een asset kon niet worden opgehaald. Probeer het later opnieuw. (code: ${error.code})`,
    "load-metering-point-data-error",
  )
}

const loadData = debounce((meteringPoint, dateRange) => {
  meteringPoint.loadMeasurements(
    freq.value,
    dateRange,
    false,
    onNewMeasurements,
    onLoadMeasurementsFailed,
  )
  // Battery-specific data
  if (meteringPoint.type === "Battery") {
    if (props.showStats) {
      meteringPoint.loadBatteryCycles(
        dateRange,
        onNewBatteryCycles,
        onLoadBatteryCyclesFailed,
      )
    }
    if (props.showAnnotations) {
      meteringPoint.loadFcrAllocations(
        dateRange,
        (data) =>
          onNewAnnotations(
            data,
            "FCR allocatie",
            colors.finance,
            "capacity_kw",
          ),
        onLoadAnnotationsFailed,
      )
    }
  }
  // All assets
  if (props.showAnnotations) {
    meteringPoint.loadSetpoints(
      dateRange,
      (data) =>
        onNewAnnotations(data, "Onbalans setpoints", colors.orange, "value"),
      onLoadAnnotationsFailed,
    )
  }
}, DEBOUNCE_DELAY)

watch(
  [() => props.meteringPoint, () => props.dateRange],
  ([meteringPoint, dateRange]) => {
    if (meteringPoint?.role !== ASSET) {
      return
    }
    loading.value = true
    loadingFailed.value = false
    chartData.value = {}
    annotationData.value = []
    loadData(meteringPoint, dateRange)
  },
  { immediate: true },
)
</script>
