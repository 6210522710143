<template>
  <div
    class="navigation-container sticky top-0 z-40"
    :class="menuIsVisible ? 'drawer-is-visible' : ''"
  >
    <div
      class="top-bar hidden items-center justify-between border-b border-gray-100"
    >
      <RouterLink :to="{ name: 'dashboard' }" class="logo cursor-pointer">
        <logoSvg class="logo h-16 w-full fill-primary px-4 md:px-6" />
      </RouterLink>
      <BaseButton
        class="navigation-drawer-logo"
        color="subtle"
        @click="openMenu"
      >
        <BaseIcon class="text-primary" name="menu" />
      </BaseButton>
    </div>
    <div
      class="navigation-drawer flex h-full w-60 shrink-0 flex-col overflow-y-auto bg-primary text-white"
    >
      <!-- Clickable logo -->
      <div class="logo-container flex items-center justify-between">
        <RouterLink :to="{ name: 'dashboard' }" class="cursor-pointer">
          <logoSvg
            class="logo h-16 w-full fill-white px-6 hover:bg-primary-400"
          />
        </RouterLink>
        <BaseButton
          class="navigation-drawer-logo"
          :class="menuIsVisible ? '' : 'hidden'"
          @click="closeMenu"
        >
          <BaseIcon name="close" />
        </BaseButton>
      </div>
      <!-- Menu items -->
      <nav>
        <ul>
          <li v-for="(item, index) in props.items" :key="index" class="group">
            <!-- Section header -->
            <div
              v-if="
                index === 0 || item.section !== props.items[index - 1].section
              "
              class="px-6 py-3 text-xs font-medium text-primary-100 group-first:mt-2"
            >
              {{ item.section }}
            </div>
            <!-- Menu item -->
            <component
              :is="item.route ? 'router-link' : 'div'"
              :to="{ name: item.route }"
              class="flex cursor-pointer items-center gap-2 whitespace-nowrap px-6 py-3 font-medium hover:bg-primary-400"
              active-class="bg-primary-600 !cursor-default hover:!bg-primary-600"
              @click="item.action && item.action()"
            >
              <div class="relative flex items-center">
                <!-- Ping indicator -->
                <div
                  v-if="item.ping"
                  class="absolute -left-1 -top-1 size-3 rounded-full bg-danger"
                >
                  <div
                    v-if="pingAnimate"
                    class="size-full animate-ping rounded-full bg-danger"
                  />
                </div>
                <!-- Icon -->
                <BaseIcon
                  v-if="item.icon"
                  :name="item.icon"
                  filled
                  class="text-xl"
                />
              </div>
              <TextLabel class="truncate">
                <slot :name="item.name">{{ item.name }}</slot>
              </TextLabel>
            </component>
            <!-- Section divider -->
            <BaseDivider
              v-if="
                index === props.items.length - 1 ||
                item.section !== props.items[index + 1].section
              "
              class="mt-4 !border-primary-300"
            />
          </li>
        </ul>
      </nav>
      <div class="grow" />
      <!-- User info -->
      <div
        v-if="sessionStore.isAuthenticated"
        class="flex items-center gap-2 px-6 py-3"
      >
        <BaseIcon name="account-circle" filled class="text-4xl" />
        <div v-if="userStore.fullName">
          <p>{{ userStore.fullName }}</p>
          <p class="text-xs">{{ userStore.email }}</p>
        </div>
        <p v-else>{{ userStore.email }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent, watch } from "vue"
import { useRoute } from "vue-router"
import useSessionStore from "@/stores/sessionStore.js"
import useUserStore from "@/stores/userStore.js"
import {
  BaseIcon,
  BaseDivider,
  BaseButton,
  TextLabel,
} from "@repowerednl/ui-component-library"

const props = defineProps({
  /**
   * Each item
   */
  items: {
    type: Array,
    required: true,
  },
})

const route = useRoute()
const sessionStore = useSessionStore()
const userStore = useUserStore()
const pingAnimate = ref(true)
let pingTimer = null

const logoSvg = computed(() => {
  return defineAsyncComponent(() => import(`/src/assets/repowered-logo.svg`))
})

/**
 * Show ping animation for 10 seconds when menu items change
 */
watch(
  () => props.items,
  () => {
    clearInterval(pingTimer)
    pingAnimate.value = true
    pingTimer = setInterval(() => {
      pingAnimate.value = false
    }, 10000)
  },
  { immediate: true },
)

/**
 * Menu visibility
 */
const menuIsVisible = ref(false)

function openMenu() {
  menuIsVisible.value = true
}

function closeMenu() {
  menuIsVisible.value = false
}

watch(
  () => route.name,
  () => {
    if (menuIsVisible.value) {
      closeMenu()
    }
  },
)
</script>

<style lang="postcss">
@media (max-width: 768px) {
  .top-bar {
    @apply !flex bg-white;
    .logo {
      width: 15em;
    }
  }
  .navigation-drawer {
    @apply fixed left-0 top-0 z-50 !w-full translate-x-full transition-transform;
    .logo {
      width: 15em;
    }
  }
  .drawer-is-visible {
    .navigation-drawer {
      @apply !translate-x-0;
    }
    .navigation-drawer-logo {
      @apply !block;
    }
  }
}
</style>
