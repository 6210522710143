const baseConfig = {
  API_URL: "",
  LEGACY_RESULTS_BREAKPOINT: new Date("2044-12-31T23:00:00Z"),
}

const envConfigs = {
  "dev-json": {
    API_URL: "http://localhost:3000",
  },
  "dev-django": {
    API_URL: "http://localhost:8080",
  },
  test: {
    API_URL: "repowered.test",
  },
  dev: {},
  qa: {},
  prod: {},
}

export default { ...baseConfig, ...envConfigs[import.meta.env.MODE] }
